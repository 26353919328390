@import "@/assets/scss/style.scss";

.documents-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 2% 5%;

        @media (max-width: 768px) {
            padding: 2%;
            flex-direction: column;
        }

        &__left {
            width: 280px;
            min-width: 280px;
            display: flex;
            flex-direction: column;
            margin-right: 60px;
            margin-top: 45px;
        }

        &__right {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 45px;
            position: relative;

            .loader {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(255, 255, 255, 0.5);
                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .select-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        span {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 8px;
        }

        label {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 8px;
        }

        ::v-deep .ant-select-selection--single {
            height: 35px;
        }
    }
}

.info-span {
    font-size: 14px !important;
    font-weight: 300 !important;
    background: #e7ebfd !important;
    border-radius: 4px !important;
    padding: 3px 5px !important;
}

.projects-title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    span {
        margin-bottom: 0 !important;
    }
}

.ant-select-selection--single {
    position: relative;
    height: 32px;
    cursor: pointer;
}

::v-deep .event-details {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    padding: 0 5px;
    max-height: 5rem;

    &__header {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    &__title {
        font-weight: bold;
        margin-right: 0.3rem;
    }

    &__client {
        font-weight: bold;
        font-size: 0.7rem;
        margin-right: 0.7rem;
    }

    &__body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        & * {
            margin: 0;
        }
    }

    &__description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 2px;
    }

    &__activity {
        font-size: 20px;
        color: #6ae46a;
    }

    &__activity--disabled {
        color: rgb(206, 137, 137);
    }
}

::v-deep .fc-daygrid-event {
    .event-details__date-wrapper {
        flex-basis: 100%;
        margin: 0;
    }

    .event-details__description {
        margin: 0;
    }
}

::v-deep .fc-event-main {
    overflow: hidden;
}

::v-deep .fc-resourceTimeGridDay-view .fc-timegrid-slot-lane {
    background: transparent !important;
}

::v-deep .fc-scrollgrid-section-body td:first-child {
    border-left-width: 1px;
}

@media (max-width: 500px) {
    ::v-deep .fc-header-toolbar {
        flex-direction: column;
    }
}

@media print {
    * {
        -webkit-print-color-adjust: exact;
        -moz-print-color-adjust: exact;
        -ms-print-color-adjust: exact;
        print-color-adjust: exact !important;
    }

    .client-top-bar {
        display: none;
    }

    ::v-deep .fc-timegrid-body,
    ::v-deep .fc-timegrid-body table {
        width: 100% !important;
    }

    ::v-deep .fc-col-header {
        width: 100% !important;
    }

    ::v-deep .fc-button-group {
        display: none;
    }
}

@page {
    size: auto;
    margin: 0.5rem 0;
}

::v-deep a.fc-event {
    background: #0d89ec !important;
    border: 1px solid #0d89ec !important;
    color: #ffffff !important;
}

::v-deep a.fc-daygrid-day-number,
::v-deep a.fc-col-header-cell-cushion {
    color: #40a9ff !important;
}
